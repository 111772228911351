import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const dateFormats = [
  { value: 'MM-DD-YYYY HH:mm:ss', label: 'MM-DD-YYYY HH:mm:ss' },
  { value: 'DD-MM-YYYY HH:mm:ss', label: 'DD-MM-YYYY HH:mm:ss' },
  { value: 'MM/DD/YYYY HH:mm:ss', label: 'MM/DD/YYYY HH:mm:ss' },
  { value: 'DD/MM/YYYY HH:mm:ss', label: 'DD/MM/YYYY HH:mm:ss' },
  { value: 'YYYY-MM-DD HH:mm:ss', label: 'YYYY-MM-DD HH:mm:ss (Hanwell)' },
  { value: 'YYYY/MM/DD HH:mm:ss', label: 'YYYY/MM/DD HH:mm:ss' },
  { value: 'HH:mm:ss DD/MMM/YYYY', label: 'HH:mm:ss DD/MMM/YYYY (Hanwell hanlog32)' },
  { value: 'YYYY-MM-DD HH:mm', label: 'YYYY-MM-DD HH:mm (PEM2)' }
];

const temperatureUnits = [
  { value: 'celsius', label: 'Celsius (°C)' },
  { value: 'fahrenheit', label: 'Fahrenheit (°F)' }
];

const illuminanceUnits = [
  { value: 'lux', label: 'Lux' },
  { value: 'footcandle', label: 'Footcandle' }
];

const timezones = [
  { value: 'UTC', label: 'UTC' },
  { value: 'UTC-3', label: 'America/Buenos_Aires (UTC-3)' },
  { value: 'UTC-4', label: 'America/New_York (UTC-4)' },
  { value: 'UTC-5', label: 'America/Chicago (UTC-5)' },
  { value: 'UTC-6', label: 'America/Denver (UTC-6)' },
  { value: 'UTC-7', label: 'America/Los_Angeles (UTC-7)' },
  { value: 'UTC-3.5', label: 'Canada/Newfoundland (UTC-3:30)' },
  { value: 'UTC-4', label: 'Canada/Atlantic (UTC-4)' },
  { value: 'UTC-5', label: 'Canada/Eastern (UTC-5)' },
  { value: 'UTC-6', label: 'Canada/Central (UTC-6)' },
  { value: 'UTC-7', label: 'Canada/Mountain (UTC-7)' },
  { value: 'UTC-8', label: 'Canada/Pacific (UTC-8)' },
  { value: 'UTC+0', label: 'Europe/London (UTC+0)' },
  { value: 'UTC+1', label: 'Europe/Paris (UTC+1)' },
  { value: 'UTC+2', label: 'Europe/Helsinki (UTC+2)' }
];

function DataFormattingStep({ importConfig, onUpdateImportConfig, onError }) {
  const [dateFormat, setDateFormat] = useState(dateFormats[0].value);
  const [timezone, setTimezone] = useState('UTC');
  const [tempUnit, setTempUnit] = useState(temperatureUnits[0].value);
  const [illumUnit, setIllumUnit] = useState(illuminanceUnits[0].value);
  const [sampleData, setSampleData] = useState({
    datetime: '',
    temperature: '',
    illuminance: ''
  });
  const [parsedDate, setParsedDate] = useState('');
  const [isISODate, setIsISODate] = useState(false);
  const [validFormats, setValidFormats] = useState([]);

  useEffect(() => {
    const uploadFile = importConfig.uploadFiles?.[0];
    if (uploadFile) {
      const getSampleForColumn = (columnType) => {
        if (!columnType) return '';
        
        const startIndex = uploadFile.parserConfig.from_line || 1;
        const firstRows = uploadFile.firstRows.slice(
          startIndex,
          startIndex + 4
        );
        
        const columnTypes = uploadFile.parserConfig.metadata.columntypes;
        const columnName = Object.keys(columnTypes).find(key => columnTypes[key] === columnType);
        
        if (!columnName) return '';

        const possibleColumnNames = [
          columnName,
          columnName.trim(),
        ];

        for (const row of firstRows) {
          for (const possibleName of possibleColumnNames) {
            if (row[possibleName] && row[possibleName].length > 0) {
              return row[possibleName];
            }
          }
        }
        
        return '';
      };

      const datetime = getSampleForColumn('datetime');
      const temperature = getSampleForColumn('tempf');
      const illuminance = getSampleForColumn('vis');
      
      const isISO = dayjs(datetime).isValid() && datetime.includes('T');
      setIsISODate(isISO);
      
      setSampleData({
        datetime,
        temperature,
        illuminance
      });
    }
  }, [importConfig]);

  useEffect(() => {
    if (sampleData.datetime) {
      const validDateFormats = dateFormats.filter(format => {
        try {
          const date = dayjs(sampleData.datetime, format.value, true);
          
          if (date.isValid()) {
            if (sampleData.datetime.includes('-')) {
              const pattern = /^\d{2}-\d{2}-\d{4}/;
              if (pattern.test(sampleData.datetime)) {
                return format.value.includes('MM-DD-YYYY') || 
                       format.value.includes('DD-MM-YYYY');
              }
            }
            
            const normalizedInput = sampleData.datetime.replace(/\s+/g, ' ').trim();
            const normalizedOutput = date.format(format.value).replace(/\s+/g, ' ').trim();
            return normalizedInput === normalizedOutput;
          }
          return false;
        } catch {
          return false;
        }
      });

      setValidFormats(validDateFormats);

      if (validDateFormats.length > 0 && !validDateFormats.find(f => f.value === dateFormat)) {
        setDateFormat(validDateFormats[0].value);
        handleChange('dateFormat', validDateFormats[0].value);
      }
    }
  }, [sampleData.datetime]);

  useEffect(() => {
    if (sampleData.datetime) {
      try {
        let date;
        if (isISODate) {
          const matches = sampleData.datetime.match(/([-+]\d{2}:?\d{2}|Z)$/);
          if (matches) {
            date = dayjs(sampleData.datetime);
            const offset = matches[0];
            setParsedDate(date.format(`MMMM D, YYYY h:mm:ss A [UTC${offset === 'Z' ? '' : offset}]`));
          } else {
            const offsetMatch = sampleData.datetime.match(/-\d{2}:00$/);
            if (offsetMatch) {
              date = dayjs(sampleData.datetime);
              setParsedDate(date.format(`MMMM D, YYYY h:mm:ss A [UTC${offsetMatch[0]}]`));
            }
          }
        } else {
          const formatToUse = dateFormat === 'HH:mm:ss DD/MMM/YYYY' 
            ? 'HH:mm:ss  DD/MMM/YYYY'
            : dateFormat;
          
          date = dayjs(sampleData.datetime, formatToUse, true);
          if (date.isValid()) {
            setParsedDate(date.format(`MMMM D, YYYY h:mm:ss A [${timezone}]`));
          } else {
            setParsedDate('Invalid date format');
          }
        }
      } catch (error) {
        setParsedDate('Error parsing date');
        console.error('Error parsing date:', error);
      }
    }
  }, [sampleData.datetime, dateFormat, isISODate, timezone]);

  useEffect(() => {
    if (importConfig.uploadFiles?.[0] && !importConfig.format) {
      const initialFormat = importConfig.uploadFiles[0].parserConfig.metadata?.dateformat;
      if (initialFormat) {
        setDateFormat(initialFormat);
      }

      onUpdateImportConfig({
        ...importConfig,
        format: {
          date: isISODate ? null : (initialFormat || dateFormat),
          timezone: isISODate ? null : 'UTC',
          temperature: tempUnit === 'celsius' ? 'C' : 'F',
          light: illumUnit,
        }
      });
    }
  }, []);

  useEffect(() => {
    const { format } = importConfig;
    if (format) {
      const isValid = isISODate ? 
        Boolean(format.temperature && format.light) :
        Boolean(
          format.date && 
          format.timezone && 
          format.temperature && 
          format.light
        );

      let isDateFormatValid = true;
      if (!isISODate && sampleData.datetime) {
        const parsedDate = dayjs(sampleData.datetime, format.date);
        isDateFormatValid = parsedDate.isValid();
      }

      onUpdateImportConfig({
        ...importConfig,
        formatResult: isValid && isDateFormatValid
      });
    }
  }, [importConfig.format, isISODate, sampleData.datetime]);

  const handleChange = (field, value) => {
    let newFormat = { ...importConfig.format };

    switch (field) {
      case 'dateFormat':
        setDateFormat(value);
        newFormat.date = isISODate ? null : value;
        break;
      case 'timezone':
        setTimezone(value);
        newFormat.timezone = isISODate ? null : value;
        break;
      case 'temperatureUnit':
        setTempUnit(value);
        newFormat.temperature = value === 'celsius' ? 'C' : 'F';
        break;
      case 'illuminanceUnit':
        setIllumUnit(value);
        newFormat.light = value;
        break;
    }

    onUpdateImportConfig({
      ...importConfig,
      format: newFormat
    });
  };

  return (
    <Box p={3}>
      <Typography variant="h5" gutterBottom>
        Configure Data Format
      </Typography>
      <Typography variant="body2" color="textSecondary" paragraph>
        Please verify how your data will be interpreted before importing
      </Typography>
      
      <Box display="grid" gridGap={32} gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))">
        <Box bgcolor="background.default" p={3} borderRadius={1} boxShadow={1}>
          <Typography variant="subtitle1" gutterBottom color="primary">
            Date & Time Configuration
          </Typography>
          
          <Box mb={2}>
            <Typography variant="caption" color="textSecondary">
              Sample from your data
            </Typography>
            <Typography variant="body1" gutterBottom fontWeight="medium">
              {sampleData.datetime || 'No date found'}
            </Typography>
          </Box>

          {parsedDate && (
            <Box mb={2}>
              <Typography variant="caption" color="textSecondary">
                Will be interpreted as
              </Typography>
              <Typography variant="body1" color="primary" fontWeight="medium">
                {parsedDate}
              </Typography>
            </Box>
          )}

          {isISODate ? (
            <Box mt={2} p={2} bgcolor="success.light" borderRadius={1}>
              <Typography variant="body2">
                ✓ ISO 8601 format detected - Your dates will be parsed automatically
              </Typography>
            </Box>
          ) : (
            <>
              <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: '1rem' }}>
                <InputLabel>Date Format</InputLabel>
                <Select
                  value={dateFormat}
                  onChange={(e) => handleChange('dateFormat', e.target.value)}
                  label="Date Format"
                >
                  {validFormats.map((format) => (
                    <MenuItem key={format.value} value={format.value}>
                      {format.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Time Zone</InputLabel>
                <Select
                  value={timezone}
                  onChange={(e) => handleChange('timezone', e.target.value)}
                  label="Time Zone"
                >
                  {timezones.map((tz) => (
                    <MenuItem key={tz.value} value={tz.value}>
                      {tz.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </Box>

        {sampleData.temperature && (
          <Box bgcolor="background.default" p={3} borderRadius={1} boxShadow={1}>
            <Typography variant="subtitle1" gutterBottom color="primary">
              Temperature Configuration
            </Typography>
            
            <Box mb={2}>
              <Typography variant="caption" color="textSecondary">
                Sample from your data
              </Typography>
              <Typography variant="body1" gutterBottom fontWeight="medium">
                {sampleData.temperature}° {tempUnit === 'celsius' ? 'C' : 'F'}
              </Typography>
            </Box>

            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel>Interpret Temperature As</InputLabel>
              <Select
                value={tempUnit}
                onChange={(e) => handleChange('temperatureUnit', e.target.value)}
                label="Interpret Temperature As"
              >
                {temperatureUnits.map((unit) => (
                  <MenuItem key={unit.value} value={unit.value}>
                    {unit.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        {sampleData.illuminance && (
          <Box bgcolor="background.default" p={3} borderRadius={1} boxShadow={1}>
            <Typography variant="subtitle1" gutterBottom color="primary">
              Light Level Configuration
            </Typography>
            
            <Box mb={2}>
              <Typography variant="caption" color="textSecondary">
                Sample from your data
              </Typography>
              <Typography variant="body1" gutterBottom fontWeight="medium">
                {sampleData.illuminance} {illumUnit === 'lux' ? 'lux' : 'fc'}
              </Typography>
            </Box>

            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel>Interpret Light Levels As</InputLabel>
              <Select
                value={illumUnit}
                onChange={(e) => handleChange('illuminanceUnit', e.target.value)}
                label="Interpret Light Levels As"
              >
                {illuminanceUnits.map((unit) => (
                  <MenuItem key={unit.value} value={unit.value}>
                    {unit.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default DataFormattingStep; 