import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Box,
	Toolbar,
	Typography,
	Button,
	ListItemText,
	ListItemIcon,
	Avatar,
	Menu,
	MenuItem,
	Fade,
	Divider,
	Modal
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { useStoreon } from 'storeon/react';
import Icon from '@mdi/react';
import icons from '../../style/icons';
import SegmentUtil from '../../util/SegmentUtil';
import ConservNavLink from './ConservNavLink';
import Api from '../../api/Api';
import { avatarStyles, navStyles } from '../../style/styles';
import { colors } from '../../style/colors';
import { NetworkActivityTracker } from '../../../utils/network/NetworkActivityTracker';
import { useInjection } from 'inversify-react';
import { DI_TYPES } from 'diTypes';

const mnmxIoCommon = require('@conserv/mnmx-io-common');
const LOG = mnmxIoCommon.getConsoleLogger('TopNavContents.js');

const useStyles = makeStyles(theme => ({
	sectionTitle: {
		color: 'black'
	},
	icon: {
		color: '#fff',
		'&:hover': {
			color: props => (props.drawerOpen ? '#FFF' : '#E5E5E5')
		}
	},
	menuItem: {
		color: '#000',
		'&:hover': {
			// backgroundColor: '#5F6971'
			backgroundColor: colors.ice.lightOpacity
		},
		width: '275px'
	},
	text: {
		color: '#000',
		'&:hover': {
			fontWeight: 'bold'
		}
	},
	menuItemText: {
		color: '#000',
		maxWidth: theme.spacing(12)
	},
	personalMenu: {
		// backgroundColor: theme.palette.primary.main
		color: 'black'
	},
	...avatarStyles,
	...navStyles
}));

function TopNavContents(props) {
	const classes = useStyles();
	const { dispatch, navState } = useStoreon('navState');
	const history = useHistory();

	const developerModeService = useInjection(DI_TYPES.DeveloperModeService);
	LOG.debug(
		`TopNavContents(): By default developerMode is ${
			developerModeService.isEnabled() ? 'ENABLED' : 'DISABLED'
		}`
	);

	// https://stackoverflow.com/questions/53845595/wrong-react-hooks-behaviour-with-event-listener?rq=3
	const [goodIconEnabledFlag, setGoodIcon] = React.useState(
		NetworkActivityTracker.isGoodIconEnabled()
	);
	const [warnIconEnabledFlag, setWarnIcon] = React.useState(
		NetworkActivityTracker.isWarnIconEnabled()
	);
	const [devModeEnabledFlag, setDevModeEnabledFlag] = React.useState(
		developerModeService.isEnabled()
	);

	const [trafficLightModalFlag, setTrafficLightModalOpenStatus] =
		React.useState(false);

	const handleTrafficLightModalOpen = () => {
		setTrafficLightModalOpenStatus(true);
	};
	const handleTrafficLightModalClose = () => {
		setTrafficLightModalOpenStatus(false);
	};

	const avatar = props.user ? props.user.avatar : null;
	const fullName = props.user ? props.user.fullName : null;
	const email = props.user ? props.user.email : null;
	const customerName = props.customer ? props.customer.name : null;

	const [anchor, setAnchor] = React.useState(NetworkActivityTracker.is);
	const [anchorAcct, setAnchorAcct] = React.useState(null);

	useEffect(() => {
		const subscription = NetworkActivityTracker.getChangeObservable().subscribe(
			() => {
				handleTrafficIconStateChange();
			}
		);

		const subscription2 = developerModeService
			.getChangeObservable()
			.subscribe(() => {
				handleTrafficIconStateChange();
			});

		return () => {
			subscription.unsubscribe();
			subscription2.unsubscribe();
		};
	}, []);

	const handleTrafficIconStateChange = event => {
		setGoodIcon(NetworkActivityTracker.isGoodIconEnabled());
		setWarnIcon(NetworkActivityTracker.isWarnIconEnabled());
		setDevModeEnabledFlag(developerModeService.isEnabled());
	};

	const handleMenuOpen = event => {
		setAnchor(event.currentTarget);
	};

	const buildAccountListMenu = () => {
		const options = [];
		if (props.customers) {
			props.customers.forEach(customer => {
				options.push(
					<MenuItem
						classes={{ root: classes.menuItem }}
						key={customer.uuid}
						onClick={() => {
							switchAccount(customer.uuid);
						}}
					>
						<ListItemIcon>
							<Icon
								path={icons.switchAccount}
								size={1}
								className={classes.menuItem}
							/>
						</ListItemIcon>
						<Typography variant='inherit' noWrap>
							{customer.customername}
						</Typography>
					</MenuItem>
				);
			});
		}
		return options;
	};

	const switchAccount = linkuuid => {
		Api.joinCustomer(linkuuid)
			.then(() => {
				handleAcctMenuClose();
				handleMenuClose();
				dispatch('notification/add', { message: 'Switched account' });
				window.location.href = '/';
			})
			.catch(err => {
				dispatch('notification/add', {
					message: 'Error switching account',
					severity: 'error',
					variant: 'banner'
				});
			});
	};

	const handleMenuClose = () => {
		handleAcctMenuClose();
		setAnchor(null);
	};

	const handleAcctMenuOpen = event => {
		setAnchorAcct(event.currentTarget);
	};
	const handleAcctMenuClose = () => {
		setAnchorAcct(null);
	};

	const routeToReferral = () => {
		SegmentUtil.track(SegmentUtil.actions.clickreferralbutton);
		history.push('/settings?activetab=referrals');
	};

	const routeToDeveloperPage = () => {
		history.push('/ri');
	};

	const routeToCommunity = () => {
		SegmentUtil.track(SegmentUtil.actions.clickcommunitybutton);
		window.open('https://community.conserv.io', '_blank');
	};

	const enableDeveloperMode = () => {
		developerModeService.enable();
	};

	const modalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4
	};

	return (
		<>
			<Toolbar>
				<Box
					pb={1}
					display='flex'
					flexDirection='row'
					width='100%'
					justifyContent='space-between'
					alignItems='center'
				>
					<Box display='flex' flexDirection='row' alignItems='center'>
						<Box>
							<Typography classes={{ root: classes.sectionTitle }} variant='h2'>
								{navState.pageTitle}
							</Typography>
						</Box>
						{false && (
							<Box
								ml={0}
								onClick={routeToDeveloperPage}
								sx={{ cursor: 'default' }}
							>
								/ri Page
							</Box>
						)}
					</Box>

					<Box display='flex' flexDirection='row' alignItems='center'>
						<Box pr={2}>
							<Button onClick={routeToCommunity} color='primary' variant='text'>
								Community
							</Button>
						</Box>

						<Box
							data-cy='user-menu'
							onClick={handleMenuOpen}
							style={{ cursor: 'pointer' }}
							display='flex'
							flexDirection='row'
							alignItems='center'
						>
							<Avatar src={avatar}>{fullName}</Avatar>
							{anchor ? (
								<Icon
									path={icons.collapseUp}
									size={1}
									color={colors.ice.primary}
								/>
							) : (
								<Icon
									path={icons.expandDown}
									size={1}
									color={colors.ice.primary}
								/>
							)}
						</Box>
						{devModeEnabledFlag == true && (
							<Box>
								<div
									onClick={() => handleTrafficLightModalOpen()}
									style={{
										color: '#ccc',
										fontSize: '2.75rem',
										lineHeight: '1rem',
										marginBottom: '0.5rem'
									}}
								>
									{goodIconEnabledFlag == true && (
										<span style={{ color: '#32a932' }}>&#8226;</span>
									)}
									{goodIconEnabledFlag != true && (
										<span style={{ color: '#cdc' }}>&#8226;</span>
									)}
									<br />
									{warnIconEnabledFlag == true && (
										<span style={{ color: '#d2ad3e' }}>&#8226;</span>
									)}
									{warnIconEnabledFlag != true && (
										<span style={{ color: '#ddd9cc' }}>&#8226;</span>
									)}
								</div>
							</Box>
						)}

						<Modal
							open={trafficLightModalFlag}
							onClose={handleTrafficLightModalClose}
							aria-labelledby='modal-modal-title'
							aria-describedby='modal-modal-description'
						>
							<Box sx={modalStyle}>
								<Typography id='modal-modal-title' sx={{ mt: 2 }}>
									<b>When Developer Mode is enabled...</b>
								</Typography>
								<Typography id='modal-modal-description' sx={{ mt: 2 }}>
									These "traffic lights" show network traffic between this app
									and the Conserv servers. Occassionally the orange light will
									blink if there are hiccups in connectivity.
								</Typography>
							</Box>
						</Modal>
					</Box>
				</Box>
			</Toolbar>
			<Menu
				classes={{ paper: classes.personalMenu }}
				id='avatarmenu'
				anchorEl={anchor}
				anchorPosition={{ top: 60, left: window.innerWidth - 280 }}
				anchorReference='anchorPosition'
				keepMounted
				open={Boolean(anchor)}
				onClose={handleMenuClose}
				TransitionComponent={Fade}
			>
				<MenuItem classes={{ root: classes.menuItem }}>
					<ListItemIcon>
						<Avatar src={avatar} className={classes.menuAvatar}>
							{fullName}
						</Avatar>
					</ListItemIcon>
					<ListItemText
						classes={{ secondary: classes.menuItemText }}
						primary={
							<Typography noWrap style={{ fontSize: 16 }}>
								{email}
							</Typography>
						}
					/>
				</MenuItem>
				<Divider style={{ borderTop: '1px solid #FFF' }} />
				<MenuItem
					classes={{ root: classes.menuItem }}
					component={ConservNavLink}
					to='/settings?activetab=users'
					onClick={handleMenuClose}
				>
					<ListItemIcon>
						<Icon
							path={icons.addUser}
							size='24px'
							className={classes.menuItem}
						/>
					</ListItemIcon>
					<ListItemText classes={{ root: classes.menuItemText }}>
						INVITE MY TEAM
					</ListItemText>
				</MenuItem>
				<MenuItem
					classes={{ root: classes.menuItem }}
					component='a'
					href='https://support.conserv.io/knowledge/kb-tickets/new'
					target='_new'
				>
					<ListItemIcon>
						<Icon path={icons.help} size={1} className={classes.menuItem} />
					</ListItemIcon>
					<ListItemText classes={{ root: classes.menuItemText }}>
						HELP
					</ListItemText>
				</MenuItem>
				<MenuItem
					classes={{ root: classes.menuItem }}
					component={ConservNavLink}
					to='/resources'
					onClick={handleMenuClose}
				>
					<ListItemIcon>
						<Icon
							path={icons.resources}
							size={1}
							className={classes.menuItem}
						/>
					</ListItemIcon>
					<ListItemText classes={{ root: classes.menuItemText }}>
						RESOURCES
					</ListItemText>
				</MenuItem>
				<MenuItem
					classes={{ root: classes.menuItem }}
					component={ConservNavLink}
					to='/settings'
					onClick={handleMenuClose}
				>
					<ListItemIcon>
						<Icon
							path={icons.settings}
							size='24px'
							className={classes.menuItem}
						/>
					</ListItemIcon>
					<ListItemText classes={{ root: classes.menuItemText }}>
						SETTINGS
					</ListItemText>
				</MenuItem>
				{false && (
					<MenuItem>
						<ListItemIcon></ListItemIcon>
						<ListItemText
							classes={{ root: classes.menuItemText }}
							onClick={enableDeveloperMode}
							style={{ color: '#ccc' }}
						>
							Developer Mode
						</ListItemText>
					</MenuItem>
				)}
				<Divider style={{ borderTop: '1px solid #FFF' }} />
				<MenuItem
					classes={{ root: classes.menuItem }}
					component='a'
					href='/logout'
				>
					<ListItemIcon>
						<Icon
							path={icons.logout}
							size='24px'
							className={classes.menuItem}
						/>
					</ListItemIcon>
					<ListItemText>LOGOUT</ListItemText>
				</MenuItem>
				<MenuItem
					classes={{ root: classes.menuItem }}
					onClick={handleAcctMenuOpen}
				>
					<ListItemIcon>
						<Icon
							path={icons.switchAccount}
							size='24px'
							className={classes.menuItem}
						/>
					</ListItemIcon>
					<ListItemText
						primary='SWITCH ACCOUNT'
						secondary={<Typography noWrap>{customerName}</Typography>}
						classes={{ secondary: classes.menuItemText }}
					/>
				</MenuItem>
			</Menu>
			<Menu
				classes={{ paper: classes.personalMenu }}
				id='acctmenu'
				anchorEl={anchorAcct}
				anchorPosition={{ top: 350, left: window.innerWidth - 560 }}
				anchorReference='anchorPosition'
				keepMounted
				open={Boolean(anchorAcct)}
				onClose={handleAcctMenuClose}
				TransitionComponent={Fade}
			>
				{buildAccountListMenu()}
			</Menu>
		</>
	);
}

export default TopNavContents;
