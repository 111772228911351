import React from 'react';
import { Box, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { cardStyles } from '../../../style/styles';

const useStyles = makeStyles({
	...cardStyles,
	cardContainer: {
		display: 'grid',
		gridTemplateColumns: 'repeat(4, 120px)',
		gap: '24px',
		width: 'fit-content',
		margin: '0 auto',
		justifyContent: 'center',
		justifyItems: 'center'
	},
	importOptionCard: {
		width: '120px',
		height: '120px',
		padding: '12px',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		boxSizing: 'border-box',
		margin: 0,
		'&:hover': {
			transform: 'translateY(-2px)',
			boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
		}
	},
	activeImportOptionCard: {
		width: '120px',
		height: '120px',
		padding: '12px',
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		boxSizing: 'border-box',
		margin: 0,
		backgroundColor: '#f5f5f5',
		border: '2px solid #1976d2'
	},
	cardTitle: {
		fontSize: '1.1rem',
		fontWeight: 500,
		marginBottom: '4px',
		textAlign: 'center',
		width: '100%'
	},
	cardSubtitle: {
		fontSize: '0.85rem',
		color: '#666',
		wordBreak: 'break-word',
		textAlign: 'center',
		width: '100%'
	},
	headerContainer: {
		marginBottom: '32px',
		maxWidth: '800px',
		width: '100%',
		padding: '0 24px'
	},
	title: {
		fontSize: '1.5rem',
		fontWeight: 500,
		marginBottom: '8px'
	},
	subtitle: {
		fontSize: '1rem',
		color: '#666'
	},
	outerContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '0 24px'
	}
});

function FileTypeStep(props) {
	const classes = useStyles();

	const setFileType = type => {
		const newConfig = { ...props.importConfig };
		newConfig.fileType = type;
		props.onUpdateImportConfig(newConfig);
	};

	return (
		<Box pt={4} className={classes.outerContainer}>
			<Box className={classes.headerContainer}>
				<Typography className={classes.title}>Choose your sensor type</Typography>
				<Typography className={classes.subtitle}>
					We support data from many different loggers and building management systems
				</Typography>
			</Box>
			
			<Box className={classes.cardContainer}>
				<Card
					onClick={() => setFileType('hobowaresingle')}
					className={props.importConfig.fileType === 'hobowaresingle' ? classes.activeImportOptionCard : classes.importOptionCard}
				>
					<Typography className={classes.cardTitle}>OnSet</Typography>
					<Typography className={classes.cardSubtitle}>HOBO .csv</Typography>
				</Card>

				<Card
					onClick={() => setFileType('pem2')}
					className={props.importConfig.fileType === 'pem2' ? classes.activeImportOptionCard : classes.importOptionCard}
				>
					<Typography className={classes.cardTitle}>IPI</Typography>
					<Typography className={classes.cardSubtitle}>PEM/PEM2 .csv .pm2</Typography>
				</Card>

				<Card
					onClick={() => setFileType('csv')}
					className={props.importConfig.fileType === 'csv' ? classes.activeImportOptionCard : classes.importOptionCard}
				>
					<Typography className={classes.cardTitle}>Other Loggers</Typography>
					<Typography className={classes.cardSubtitle}>.csv</Typography>
				</Card>

				<Card
					onClick={() => setFileType('hanwell')}
					className={props.importConfig.fileType === 'hanwell' ? classes.activeImportOptionCard : classes.importOptionCard}
				>
					<Typography className={classes.cardTitle}>Hanwell</Typography>
					<Typography className={classes.cardSubtitle}>.csv</Typography>
				</Card>
			</Box>
		</Box>
	);
}

export default FileTypeStep;
